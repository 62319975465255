<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page_header_new";
import appConfig from "@/app.config";
import axios from "axios";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

/**
 * Bootstrap Basic component
 */
export default {
  page: {
    title: "발급한 쿠폰 목록",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "발급한 쿠폰 목록",
      items: [
        {
          text: "Tables",
        },
        {
          text: "Bootstrap Basic",
          active: true,
        },
      ],
      tableData: []
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods: {
    //쿠폰목록 받아오기
    getCouponList() {
      axios.get(`/api/coupon/market/A/${this.logged_info.mart_srl}`).then((response) => {
        //console.log(response.data)
        let tmptable = response.data
        //표에 정보를 띄우기 위해 데이터 가공
        for (var i = 0; i < tmptable.length; i++) {
          if (tmptable[i].method.toLowerCase() === "m") {
            tmptable[i].method = "다운로드(다회성)"
          } else if (tmptable[i].method.toLowerCase() === "s") {
            tmptable[i].method = "일회성(개별발급)"
          } else if (tmptable[i].method.toLowerCase() === "ds") {
            tmptable[i].method = "다운로드(일회성)"
          }

          if (tmptable[i].place_used.toLowerCase() === "online") {
            tmptable[i].place_used = "앱전용"
          } else if (tmptable[i].place_used.toLowerCase() === "offline") {
            tmptable[i].place_used = "매장전용"
          } else if (tmptable[i].place_used.toLowerCase() === "both") {
            tmptable[i].place_used = "앱/매장겸용"
          }

          if (tmptable[i].release.toLowerCase() === "y") {
            tmptable[i].release = "배포중"
          } else if (tmptable[i].release.toLowerCase() === "n") {
            tmptable[i].release = "배포종료"
          }

          if (tmptable[i].type.toLowerCase() === "price") {
            tmptable[i].type = "정액할인"
            tmptable[i].value = response.data[i].value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원";
          } else if (tmptable[i].type.toLowerCase() === "gift") {
            tmptable[i].type = "증정"
            tmptable[i].value = response.data[i].product_name + " 증정"
          }

          if (tmptable[i].target.toLowerCase() === "all") {
            tmptable[i].target = "전체회원"
          } else if (tmptable[i].target.toLowerCase() === "mymart") {
            tmptable[i].target = "마이마켓찜 회원"
          }
        }

        this.tableData = tmptable
      }).catch(error => {
        console.log(error)
      })
    },
    //배포중인 쿠폰 강제로 중지
    releaseCancel(coupon_issue_srl) {
      this.$bvModal.msgBoxConfirm(`해당 쿠폰 배포를 중지하시겠습니까?`, msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              axios.delete(`/api/coupon/market/${this.logged_info.mart_srl}`, {data: {coupon_issue_srl: coupon_issue_srl}})
                  .then((response) => {
                    console.log(response.data)
                    if (response.data.status === 'success') {
                      this.$bvModal.msgBoxOk('해당 쿠폰배포가 중지되었습니다.', msgModalOptCenter);
                      this.getCouponList()
                    } else {
                      this.$bvModal.msgBoxOk('해당 쿠폰배포중지가 실패하였습니다.\n다시 시도해주세요.', msgModalOptCenter);
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
            }
          })
    }
  },
  mounted() {
    this.getCouponList();
  },
  computed: {
    ...mapState(loggedInfoObject),
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <!-- 표 헤드 -->
                <thead class="table-light" style="text-align: center;">
                <tr>
                  <th>쿠폰시리얼</th>
                  <th>쿠폰유형</th>
                  <th>쿠폰종류</th>
                  <th>사용처</th>
                  <th>대상자</th>
                  <th>사용기간</th>
                  <th>사용조건</th>
                  <th>등록일자</th>
                  <th>배포여부</th>
                  <th></th>
                </tr>
                </thead>
                <!-- 표에 데이터 삽입 -->
                <tbody>
                <tr
                    v-for="(row) in this.tableData"
                    :key="row.coupon_issue_srl"
                    style="vertical-align: middle;"
                >
                  <th scope="row" style="text-align: center;">{{ row.coupon_issue_srl }}</th>
                  <td style="text-align: center;">{{ row.method }}</td>
                  <td style="text-align: center;">{{ row.type }}({{ row.value }})</td>
                  <td style="text-align: center;">{{ row.place_used }}</td>
                  <td style="text-align: center;">{{ row.target }}</td>
                  <td style="text-align: center;">{{ row.start_date }} ~ {{ row.end_date }}</td>
                  <td style="text-align: center;">{{ row.comment }}</td>
                  <td style="text-align: center;">{{ row.reg_date }}</td>
                  <td style="text-align: center;">{{ row.release }}</td>
                  <!-- 배포중지버튼영역 시작 -->
                  <td style="text-align: center;">
                    <b-button variant="outline-danger" v-show="row.release === '배포중'"
                              @click="releaseCancel(row.coupon_issue_srl)">
                      배포중지
                    </b-button>
                  </td>
                  <!-- 배포중지버튼영역 끝 -->
                </tr>
                </tbody><!-- end tbody-->
              </table><!-- end table-->
            </div><!-- end table responsive -->
          </div><!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
